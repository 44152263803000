/* You can add global styles to this file, and also import other style files */

/* This is to change the default font size of bootstrap */
/* default font-size is 16px - this is set in the default browser stylesheet */

@import '~open-iconic/font/css/open-iconic-bootstrap.css';

html {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
}

td.fixed120 {
    width: 130px;
}

td.fixed80 {
    width: 80px;
}

td.fixed30 {
    width: 30px;
}

th.vtop {
    vertical-align:top;
}

/* Override bootstrap colors for btn-outline-secondary to get more kendo ui look  */
.btn-outline-secondary {
    background-color: #F5F5F5 !important;
    color: #424242 !important;
}

.btn-outline-secondary:hover {
    background-color: #EBEBEB !important;
    color: #424242 !important;
}

.form-group .required .control-label:after {
    content:"*";color:red;
}

.loader {
    left: 50%;
    margin-left: -4em;
}

.alfalaval-blue-color {
    color: #11387F;
}

.text-red {
    color: red;
}

.req-color {
    color: #a4262c;
}

.spinner-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.column_2{
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
}


/* TEST */
.input-group {
    margin-bottom: 0; /* remove the default bottom margin */
}

.input-group-prepend, .input-group-append {
    margin-top: 0; /* remove the default top margin */
}

.selectpicker {
    margin-bottom: 0; /* remove the default bottom margin for the dropdown */
}


select {
    /* width: 200px; */
    height: 29px;
    line-height: 50px;
    -webkit-appearance: menulist-button;
    -moz-appearance:none;
}

.mb10 {
    margin-bottom: 10px;
}

.mr10 {
    margin-right: 10px;
}

.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.mr5 {
    margin-right: 5px;
}

.mb5 {
    margin-bottom: 5px;
}


.mt10 {
    margin-top: 10px;
}

.xlModalClass .modal-content {
    max-width: 80%;
    width: 80%;
}

/* Overrides the modal window css */
/* .modal {
    --bs-modal-width: 80% !important;     
} */

.modal-size_80 {
    --bs-modal-width: 80%;   
}

.modal-size_70 {
    --bs-modal-width: 70%;   
}

.modal-size_60 {
    --bs-modal-width: 60%;   
}

.modal-size_50 {
    --bs-modal-width: 50%;   
}

.modal-size_40 {
    --bs-modal-width: 40%;   
}

.modal-size_30 {
    --bs-modal-width: 30%;   
}

.modal-size_20 {
    --bs-modal-width: 20%;   
}

/* ng-select height */
.ng-select.custom .ng-control {
    min-height: 18px !important;
    height: 18px !important;
}

.form-control.ng-select {
    min-height: 20px !important;
    height: 20px !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 20px !important;
}

.ng-select .ng-select-container {
    color: #333;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 29px;
    align-items: center;
}

.width150 {
    width: 150px;
}

.legend-style {
    font-size: 16px;
    font-weight: bold;
}

.redbackgroundcolor {
    background-color: #cf6d6d;
    color: #fff;
    font-weight: bold;
}